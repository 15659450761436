import { render } from "./MarchBook.vue?vue&type=template&id=b9209908&scoped=true"
import script from "./MarchBook.vue?vue&type=script&lang=js"
export * from "./MarchBook.vue?vue&type=script&lang=js"

import "./MarchBook.vue?vue&type=style&index=0&id=b9209908&lang=css"
import "./MarchBook.vue?vue&type=style&index=1&id=b9209908&scoped=true&lang=css"
import "./MarchBook.vue?vue&type=style&index=2&id=b9209908&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-b9209908"

export default script