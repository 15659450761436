<template>
<div>
  <div class="march-main-container" :class='$mq'>
    <!-- MAIN SCREEN -->
    <div class="march-main-screen" :class='$mq'>
      <div class="march-main-screen-h1" :class='$mq'>
        <h1 :class='$mq'>Создай <br>свою первую авторскую <br>
          <span class='big'>куклу</span><br>
          <span class='script'>за 2 месяца</span>
        </h1>

        <KRoundedButton class='march-main-button' :class='$mq' text-color="#fff" color="#e03955"
                        v-scroll-to="'#product'">
          Создать куклу
          <template v-slot:icon>
            <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
          </template>
        </KRoundedButton>
      </div>

      <div class="march-main-img-block" :class='$mq'>
        <div class="march-main-img" :class='$mq'>
          <KImage :src="require('@/assets/march/main-img.png')" alt="" width="100%"/>
        </div>
      </div>

      <div class="march-main-video-cont screen" :class='$mq'>
        <div class="march-main-video" :class='$mq' @click="playVideo('0K27QtULg4Q')">
          <KImage class="march-main-video-icon" :src="require('@/assets/march/main-video.png')" alt=""
                  width="60%"/>
          <p>Посмотреть на&nbsp;куклу</p>
        </div>
        <div class="march-main-video-bg" :class='$mq'></div>
      </div>

      <div class="march-main-images bokeh" :class='$mq'>
        <KImage :src="require('@/assets/march/main-bokeh.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images petals" :class='$mq'>
        <KImage :src="require('@/assets/march/main-petals.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images petals-small" :class='$mq'>
        <KImage :src="require('@/assets/march/petals.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images bee" :class='$mq'>
        <KImage :src="require('@/assets/march/main-bee.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images top-left" :class='$mq'>
        <KImage :src="require('@/assets/march/main-top-left.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images top-right" :class='$mq'>
        <KImage :src="require('@/assets/march/main-top-right.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images pink-light-left" :class='$mq'></div>
      <div class="march-main-images pink-light-right" :class='$mq'></div>
    </div>
    <KRoundedButton class='march-main-button phone' :class='$mq' text-color="#fff" color="#e03955"
                    v-scroll-to="'#product'">
      Создать куклу
      <template v-slot:icon>
        <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
      </template>
    </KRoundedButton>

    <!-- ADVANTAGES -->
    <div class="advantages-container" :class='$mq'>
      <div class="advantages-border" :class='$mq'></div>
      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>500+</p>
        <p>учеников научились создавать кукол</p>
      </div>

      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>224</p>
        <p>страницы, которые подробно опишут весь процесс </p>
      </div>

      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>1047</p>
        <p>фотографий, наглядно покажут, что сделать )</p>
      </div>
    </div>

    <!-- PRODUCT -->
    <div id='product' class="product-container" :class='$mq'>
      <div class="product book" :class='$mq'>
        <div class="product-img-container" :class='$mq'>
          <div class="product-img" :class='$mq'>
            <KImage :src="require('@/assets/march/pr-book.png')" alt="" width="100%"/>
          </div>
        </div>
        <div class="product-light" :class='$mq'></div>

        <div class="product-text" :class='$mq'>
          <h2 :class='$mq'>Делаете первые шаги в&nbsp;кукольном творчестве?</h2>
          <p>Хотите узнать как слепить красивое личико, сделать куклу подвижной, расписать ее и&nbsp;одеть?
            Предлагаю вам бестселлер для начинающих мастеров — электронная книга. Весь процесс создания
            будуарной куклы от&nbsp;А&nbsp;до&nbsp;Я в&nbsp;одном месте.</p>
          <div class="product-price" :class='$mq'>
            <p>2490&#8381;</p>
            <p class='old-price'>3000&#8381;</p>
          </div>
          <div class="product-buttons" :class='$mq'>
            <KRoundedButton @click="tryToBuy(19)" class="product-button" :class='$mq' text-color="#fff" color="#e03955"
                            v-scroll-to="'#second'">
              Купить книгу
            </KRoundedButton>

            <a target="_blank" href="/files/BeginnersBook/Book_1chapter.pdf">Посмотреть главу</a>
          </div>
        </div>
      </div>

      <div class="product-vk" :class='$mq'>
        <div class="product-vk-text" :class='$mq'>
          <h3>+ Доступ закрытую группу Вконтакте</h3>
          <p>где вы сможете делиться своими результатами, задавать вопросы и&nbsp;получать обратную связь от&nbsp;меня. </p>
        </div>

        <div class="product-vk-img-block" :class='$mq'>
          <div class="product-vk-img" :class='$mq'>
            <KImage :src="require('@/assets/march/pr-vk.png')" alt="" width="100%"/>
          </div>
        </div>
      </div>

      <div class="product-buttons-mobile" :class='$mq'>
        <KRoundedButton @click="tryToBuy(19)" class="product-button" :class='$mq' text-color="#fff" color="#e03955"
                        v-scroll-to="'#second'">
          Купить книгу
        </KRoundedButton>
        <a :class='$mq' href="/files/BeginnersBook/Book_1chapter.pdf">Посмотреть главу</a>
      </div>
    </div>
  </div>


  <!-- CHAPTERS -->
  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/1step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 1/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>С чего начать?</h3>
      <ul>
        <li>Как работать с пластиком LaDoll?</li>
        <li>Какие материалы мне нужны?</li>
        <li>Так же вы получите чертеж куклы</li>
      </ul>
    </div>
  </div>

  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/2step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 2/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>Как слепить лицо?</h3>
      <ul>
        <li>Как вставить глаза?</li>
        <li>Как слепить глаза, нос, уши, губы?</li>
        <li>Как добиться идеально ровной поверхности?</li>
      </ul>
    </div>
  </div>

  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/3step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 3/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>Как сделать подвижной?</h3>
      <ul>
        <li>Как сделать идеальные шарики?</li>
        <li>Как создать шарнирные соединения?</li>
        <li>Как собрать куклу?</li>
      </ul>
    </div>
  </div>

  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/4step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 4/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>Как расписать?</h3>
      <ul>
        <li>Какой рецепт нестирающегося грунта?</li>
        <li>Как нанести краску ровно?</li>
        <li>Как красиво расписать куклу?</li>
      </ul>
    </div>
  </div>

  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/5step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 5/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>Как сделать парик?</h3>
      <ul>
        <li>Из чего сделать волосы?</li>
        <li>Как сделать красивую линию пробора?</li>
        <li>Как завить кудряшки?</li>
      </ul>
    </div>
  </div>

  <div class="chapter-cont" :class='$mq'>
    <div class="chapter-img" :class='$mq'>
      <KImage :src="require('@/assets/march/6step.png')" class="chapter-img-in" alt="" width="100%"/>
      <p>шаг 6/6</p>
    </div>
    <div class="chapter-text" :class='$mq'>
      <h3>Как сшить одежду?</h3>
      <ul>
        <li>Как сшить платье на куклу?</li>
        <li>Как декорировать костюм?</li>
      </ul>
    </div>
  </div>


    <!-- PRODUCT 2 -->
  <div class="march-main-container" :class='$mq'>
    <div class="line-sep"></div>
    <div class="product second" :class='$mq'>
      <div class="product-text" :class='$mq'>
        <h2 :class='$mq'>Как слепить красивое детское лицо?</h2>
        <p>В этом 3-ех часовом видео мастер-классе вы узнаете: </p>
        <ul>
          <li>— маленькие секретики по работе с&nbsp;пластиком,</li>
          <li>— основные принципы соблюдения симметрии,</li>
          <li>— пропорции детского личика.</li>
          <li>— как сделать подвижную голову с&nbsp;шарниром в&nbsp;шее.</li>
        </ul>

        <div id="buy" class="product-price" :class='$mq'>
          <p>950&#8381;</p>
          <p class='old-price'>1500&#8381;</p>
        </div>

        <div class="product-buttons" :class='$mq'>
          <KRoundedButton @click="tryToBuy(12)" class="product-button" text-color="#fff" color="#e03955"
                          v-scroll-to="'#second'">
            Купить МК
          </KRoundedButton>

          <a :class='$mq' href="#" @click="playVideo('kthPid7qJYQ')">Посмотреть</a>
        </div>
      </div>

      <div class="product-img-container" :class='$mq'>
        <div class="product-ribbon" :class='$mq'>
          <div class="product-ribbon-icon" :class='$mq'>
            <KImage :src="require('@/assets/march/pr-ribbon-icon.svg')" alt="" width="100%"/>
          </div>
          <p>3 часа</p>
        </div>
        <div class="product-img child" :class='$mq'>
          <KImage :src="require('@/assets/march/pr-child.png')" alt="" width="100%"/>
        </div>
        <div class="product-light child" :class='$mq'></div>

        <div class="march-main-video-cont child" :class='$mq'>
          <div class="march-main-video" :class='$mq' @click="playVideo('kthPid7qJYQ')">
            <KImage class="march-main-video-icon" :src="require('@/assets/march/main-video.png')" alt=""
                    width="60%"/>
          </div>
          <div class="march-main-video-bg" :class='$mq'></div>
        </div>

      </div>
    </div>
    <div class="product-buttons-mobile" :class='$mq'>
      <KRoundedButton @click="tryToBuy(12)" class="product-button" :class='$mq' text-color="#fff" color="#e03955"
                      v-scroll-to="'#second'">
        Купить МК
      </KRoundedButton>
      <a :class='$mq' @click="playVideo('kthPid7qJYQ')">Посмотреть</a>
    </div>

    <!-- BUY ALL -->
    <div class="buy-all" :class='$mq'>
      <div class="buy-all-text" :class='$mq'>
        <p class="small">Книга + видео МК</p>
        <p>за 3440&#8381;</p>
      </div>

      <div class="buy-all-button" :class='$mq'>
        <KRoundedButton @click="tryToBuy(20)" class="product-button-all" :class='$mq' text-color="#fff" color="#e03955"
                        v-scroll-to="'#second'">
          Купить ВСЁ пакетом
        </KRoundedButton>
        <div class="buy-all-button-bg" :class='$mq'>
          <KImage :src="require('@/assets/march/all-bt.png')" alt="" width="100%"/>
        </div>
      </div>

      <div class="buy-all-bee" :class='$mq'>
        <KImage :src="require('@/assets/march/main-bee.png')" alt="" width="100%"/>
      </div>
      <div class="buy-all-petals" :class='$mq'>
        <KImage :src="require('@/assets/march/petals.png')" alt="" width="100%"/>
      </div>
    </div>

    <!-- REVIEWS -->
    <div class="reviews" :class='$mq'>
      <h2 class="center" :class='$mq'>Первые работы девочек <span>по&nbsp;книге</span></h2>

      <div class="review-dark left" :class='$mq'></div>
      <splide :slides="items" :options="options">
        <splide-slide v-for="(slide, index) in items" :key="index">
          <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
        </splide-slide>
      </splide>
      <div class="review-dark right" :class='$mq'></div>

      <p>Прежде всего эта книга была <b>написана для новичков</b>, для тех, кто только пробует свои силы в кукольном творчестве. Так же этот материал будет интересен и тем, кто <b>давно хотел попробовать себя в теме шарнирной куклы.</b> Это базовые знания помогут вам получить удовольствие от процесса и избежать многих ошибок. </p>
    </div>

    <!-- DOLL MOBILITY -->
    <h2 class="mobility-mobile center" :class='$mq'>Ваша кукла будет <span>невероятной</span></h2>
    <div class="doll-mobility" :class='$mq'>
      <div class="doll-mobility-text" :class='$mq'>
        <h2 class="mobility" :class='$mq'>Ваша кукла будет невероятной</h2>
        <ul>
          <li>— Слепите ей сами головку, ножки, ручки.</li>
          <li>— Попробуете себя в теме шарниров, что добавит вашей кукле подвижности.</li>
          <li>— Создадите ей прическу и&nbsp;костюм.</li>
          <li>— А ещё вы поймаете себя на мысли, что играть с&nbsp;ней можно часами и&nbsp;это доставляет вам огромное
            удовольствие и&nbsp;не&nbsp;только вам :)
          </li>
        </ul>
        <p></p>
        <KRoundedButton class="product-button-all end" text-color="#fff" color="#e03955" v-scroll-to="'#buy'">
          Создать куклу
        </KRoundedButton>
      </div>

      <div class="doll-mobility-img-block" :class='$mq'>
        <div class="doll-mobility-img" :class='$mq'>
          <KImage :src="require('@/assets/march/doll-mobility.png')" alt="" width="100%"/>
        </div>
        <div class="march-main-video-cont mobility" :class='$mq'>
          <div class="march-main-video" :class='$mq' @click="playVideo('0K27QtULg4Q')">
            <KImage :src="require('@/assets/march/main-video.png')" alt="" width="60%"/>
            <p>Посмотрите</p>
          </div>
          <div class="march-main-video-bg" :class='$mq'></div>
        </div>
      </div>
    </div>


    <!-- ABOUT ME -->
    <div class="author-cont" :class='$mq'>
      <div class="author-img" :class='$mq'>
        <KImage :src="require('@/assets/march/Luda.png')" alt="" width="100%"/>
        <p>Людмила Кибанова</p>
        <p class="script">автор курса</p>
      </div>
      <div class="author-text" :class='$mq'>
        <h2 :class='$mq'>Я собрала весь свой творческий опыт за 10 лет</h2>
        <p>Я постаралась максимально подробно, доступно и просто изложить весь процесс создания куклы. Главное, чтобы Вы получили огромное удовольствие от творчества и в итоге держали в руках свою куклу. Я надеюсь, что мой опыт поможет Вам существенно вырасти и вдохновит Вас на новые творческие подвиги. </p>
      </div>
    </div>

    <div class="reviews author" :class='$mq'>
      <div class="review-dark left" :class='$mq'></div>
      <splide :slides="items" :options="options">
        <splide-slide v-for="(slide, index) in items" :key="index">
          <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
        </splide-slide>
      </splide>
      <div class="review-dark right" :class='$mq'></div>
    </div>

    <!-- FAQ -->
    <div class="faq" :class='$mq'>
      <h2 class="center faq" :class='$mq'>Часто задаваемые вопросы</h2>
      <accordion>
        <accordion-panel>
          <accordion-panel-header>Где мне будут доступны материалы? На какой платформе?
          </accordion-panel-header>
          <accordion-panel-content>Все материалы будут доступны на этом сайте <a href="https://kibanovadolls.ru/mycourses">в вашем личном кабинете.</a>
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Книга только электронная или есть бумажная версия?</accordion-panel-header>
          <accordion-panel-content>Бумажной версии, к сожалению нет. Книга в только электронном видео.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Это подходит для начинающих?</accordion-panel-header>
          <accordion-panel-content>Прежде всего эта книга была <b>написана для новичков</b>, для тех, кто
            только пробует свои силы в кукольном творчестве. Так же этот материал будет интересен и тем, кто
            давно <b>хотел попробовать себя в теме шарнирной куклы</b>. Это базовые знания помогут вам
            получить удовольствие от процесса и избежать многих ошибок. Применив эти знания в своем
            творчестве Вы в разы увеличите выразительность образа своей куклы, а также решите, хотите ли вы
            дальше изучать строение шарниров.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Как долго я смогу изучать материал? Есть ли ограничения по времени?
          </accordion-panel-header>
          <accordion-panel-content>Материал доступен 1 год. После вы сможете продлить доступ по вашему
            желанию.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Смогу ли я изучать курс в удобное для меня время?</accordion-panel-header>
          <accordion-panel-content>Конечно, когда вам удобно, на любом устройстве.</accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Я получу доступ сразу после оплаты?</accordion-panel-header>
          <accordion-panel-content>Да, вам на почту придет письмо с дальнейшими инструкциями.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Какие материалы нужны для создания куклы?</accordion-panel-header>
          <accordion-panel-content><a target="_blank" href="/mycourses/bodoir-materials">Здесь можно посмотреть подробный список</a> всех материалов (чтобы его увидеть
            нужно авторизоваться)
          </accordion-panel-content>
        </accordion-panel>
      </accordion>
    </div>

  </div>
</div>
</template>

<script>
import KImage from "../../Widgets/KImage";
import KRoundedButton from "../../Widgets/KRoundedButton";
import KIHandFlower from "../../Widgets/Icons/KIHandFlower";
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


import image1 from "@/assets/newyear/reviews/Beg1.jpg"
import image2 from "@/assets/newyear/reviews/Beg2.jpg"
import image3 from "@/assets/newyear/reviews/Beg3.jpg"
import image4 from "@/assets/newyear/reviews/Beg4.jpg"
import image5 from "@/assets/newyear/reviews/Beg5.jpg"
import image6 from "@/assets/newyear/reviews/Beg6.jpg"

export default {
  name: "MarchBook",
  components: {
    KImage,
    KRoundedButton,
    KIHandFlower,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      items: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
      ],
      chapterItems: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
      ],
      options: {
        rewind: true,
        perPage: 4,
        gap: '0.3rem',
        pagination: false,
        // cover: true,
        breakpoints: {
          450: {
            perPage: 1,
          },
          700: {
            perPage: 2,
          },
          1000: {
            perPage: 3,
          },
        },
      },
    }
  },
  methods: {
    tryToBuy(id) {
      this.$router.push("/item/" + id)
    },
    showImage(src) {
      this.$swal.fire({
        // width: '80%',
        height: '90%',
        background: '#eae2e0',
        html: '<img width="100%" src="' + src + '"/>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
    playVideo(videoId) {
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoHeight = videoWidth / 1.77
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\"" + videoWidth + "\" height=\"" + videoHeight + "\" src=\"https://www.youtube.com/embed/" + videoId + "?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: ""
      })
    }
  }
}
</script>

<style>
  .march-main-video-cont {
    animation: 1s infinite scaling;
  }
  @keyframes scaling {
    0% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.0);
    }
  }

</style>

<style scoped>

.author-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
}
  .author-cont.halftablet,
  .author-cont.mobile {
    flex-wrap: wrap;
    justify-content: center;
  }
  .author-img {
    width: 45%;
    position: relative;
  }
    .author-img.halftablet,
    .author-img.mobile {
      width: 100%;
      margin-bottom: 2em;
    }
  .author-text{
    width: 50%;
    line-height: 1.3em;
  }
    .author-text.halftablet,
    .author-text.mobile {
      width: 80%;
      margin-bottom: 2em;
    }
    .author-text.halftablet h2,
    .author-text.mobile h2 {
      text-align: center;
    }
  .author-img p {
    font-family: "Kudryashev", sans-serif;
    display: inline-block;
    font-size: 2em;    
    line-height: 1em;
    position: absolute;
    bottom:1em;
    right:2em;
  }
    .author-img.tablet p {
      font-size: 1.5em;
    }
    .author-img.halftablet p {
      font-family: "Montserrat", sans-serif;
      font-size: 1.5em;
    }
    .author-img.mobile p {
      font-family: "Montserrat", sans-serif;
      font-size: 1.3em;
    }
  .author-img p.script {
    font-family: "TheArtist", sans-serif;
    font-size: 2.5em;
    bottom: 0em;
    right: 1em;
  }
    .author-img.tablet p.script {
      bottom: -0.25em;
    }
    .author-img.halftablet p.script,
    .author-img.mobile p.script {
      bottom: -0.3em;
    }

.line-sep {
  width: 100%;
  height: 1px;
  background-color: #ffffff55;
  margin-bottom: 2em;
  margin-top: 4em;
}

.chapter-cont {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 3em auto;
  position: relative;
}
  .chapter-cont.halftablet,
  .chapter-cont.mobile {
    font-size: 0.85em;
  }

.chapter-text {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1em;
}
  .chapter-text.mobile {
    width: 80%;
  }

.chapter-img{
  position: relative;
}
  .chapter-img p {
    font-family: "TheArtist", sans-serif;
    font-size: 4em;
    line-height: 1em;
    position: absolute;
    bottom: -0.35em;
    right: 20%;
  }
  .chapter-img.mobile p {
    font-size: 3em;
  }

.chapter-text h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5em;
  line-height: 1em;
  margin-bottom: 0.3em;
}
  .chapter-text.tablet h3 {
    font-size: 2em;
  }
  .chapter-text.mobile h3 {
    font-size: 1.7em;
  }

.chapter-text ul li:before {
  content: "—";
  padding-right: 1em;
}

.march-main-container {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 2em;
}

.march-main-container.halftablet {
  padding: 0 1em;
}

.march-main-container.mobile {
  padding: 0 1em;
}

.march-main-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: -3em;
  margin-top: -4em;
}

.march-main-screen.halftablet {
  margin-top: -2em;
  justify-content: center;
}

.march-main-screen.mobile {
  margin-top: 0em;
  justify-content: center;
  align-items: stretch;
}

.march-main-screen-h1 {
  width: 33%;
  position: relative;
  z-index: 10;
  top: -2em;
}
  .march-main-screen-h1.halftablet {
    width: 40%;
    max-width: 180px;
  }
  .march-main-screen-h1.mobile {
    width: 55%;
    max-width: 180px;
    margin-bottom: 6em;
    margin-top: 2em;
  }

h1 {
  font-size: 3em;
  line-height: 1.1em;
  position: relative;
  margin-bottom: 0.5em;
  margin-top: 1em;
  z-index: 5;
}

h1.tablet {
  font-size: 2.3em;
}

h1.halftablet {
  font-size: 1.7em;
}

h1.mobile {
  font-size: 1.7em;
}

span.big {
  font-size: 1.7em;
  line-height: 1.2em;
  text-transform: uppercase;
}

span.script {
  font-family: 'TheArtist', sans-serif;
  font-size: 1.1em;
  position: absolute;
  right: 0.5em;
  top: 90%;
}

h2 {
  font-size: 3em;
  line-height: 1em;
  margin-bottom: 0.5em;
  position: relative;
}

h2.tablet,
h2.halftablet {
  font-size: 2.3em;
}

h2.mobile {
  font-size: 2em;
}

h2 span {
  font-family: 'TheArtist', sans-serif;
  position: absolute;
  bottom: -0.8em;
  right: 15%;
}

h2.tablet span,
h2.halftablet span,
h2.mobile span {
  right: 20%
}

h2.center {
  text-align: center;
  margin-bottom: 1em;
}

h2.mobility.tablet,
h2.mobility.halftablet,
h2.mobility.mobile {
  display: none;
}

h2.mobility-mobile {
  display: none;
}

h2.mobility-mobile.tablet,
h2.mobility-mobile.halftablet,
h2.mobility-mobile.mobile {
  display: block;
}

a {
  color: #ffffff;
}

a.mobile {
  font-size: 0.9em;
  line-height: 1.3em;
}

a.main {
  color: white;
  font-weight: 600;
  margin-right: 2em;
}

a::after {
  content: "";
  border-top: 1px solid #ffffff99;
  position: absolute;
  width: 100%;
  left: 0;
  right: 100%;
  bottom: 0px;
  transition: width 0.4s ease-out;
}

a:hover {
  color: white;
}

a:hover::after {
  width: 0;
}

b {
  font-weight: 800;
}

.march-main-img-block {
  position: relative;
  width: 50%;
  right: 3em;
  z-index: 1;
}

.march-main-img-block.mobile {
  /* right: 5em; */
}

.march-main-img {
  position: relative;
  width: 130%;
}

.march-main-img.mobile {
  position: absolute;
  min-width: 304px;
  width: 130%;
  top: -2em;
}

.march-main-video-cont {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  min-height: 250px;
  line-height: 1.3em;
  right: -60px;
  bottom: 2em;
  font-size: 0.8em;
  line-height: 1em;
  font-weight: 800;
  z-index: 10;
  background: radial-gradient(circle 125px, #d54d59, transparent 100%);
}

  .march-main-video-cont.halftablet {
    min-width: 200px;
    min-height: 200px;
    background: radial-gradient(circle 100px, #d54d59, transparent 100%);
    bottom: 0em;
    right: -3em;
  }
  .march-main-video-cont.mobile {
    min-width: 150px;
    min-height: 150px;
    background: radial-gradient(circle 75px, #d54d59, transparent 100%);
    bottom: 20%;
    right: -3em;
  }


.march-main-video-cont.child,
.march-main-video-cont.child.halftablet,
.march-main-video-cont.child.mobile {
  right: 50%;
  transform: translateX(50%);
  bottom: -2em;
  background: transparent;
}

.march-main-video-cont.child.halftablet,
.march-main-video-cont.child.mobile {
  min-width: 200px;
  min-height: 200px;
  bottom: 0em !important;
}


.march-main-video {
  width: 9em;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.march-main-video.halftablet,
.march-main-video.mobile {
  width: 7em;
}

.march-main-video:hover {
  transform: scale(1.1);
}

.march-main-video p {
  margin: 0.75em auto 0 auto;
}

.march-main-video.halftablet p,
.march-main-video.mobile p {
  display: none;
}

.march-main-video {
  animation: video-scale 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes video-scale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.march-main-images {
  position: absolute;
  z-index: 0;
}

.march-main-images.bokeh {
  width: 1312px;
  margin: 0 auto;
  top: -7em;
  left: 50%;
  transform: translateX(-50%);
}

.march-main-images.bokeh.tablet,
.march-main-images.bokeh.halftablet {
  width: 800px;
}

.march-main-images.bokeh.mobile {
  width: 500px;
}

.march-main-images.petals-small {
  display: none;
}
  .march-main-images.petals-small.tablet {
    display: block;
    width: 450px;
    right: 0;
    z-index: 3;
  }
  .march-main-images.petals-small.halftablet {
    display: block;
    width: 300px;
    right: 0;
    z-index: 3;
  }
  .march-main-images.petals-small.mobile {
    display: block;
    width: 250px;
    right: -3em;
    z-index: 3;
  }

.march-main-images.petals {
  width: 934px;
  right: -4em;
  top: -3em;
  z-index: 2;
}

.march-main-images.petals.tablet,
.march-main-images.petals.halftablet,
.march-main-images.petals.mobile {
  width: 600px;
  display: none;
}

.march-main-images.top-left {
  width: 488px;
  top: -3em;
  left: -5em;
  z-index: 1;
}

.march-main-images.top-left.tablet {
  width: 380px;
  top: -2.3em;
}

.march-main-images.top-left.halftablet,
.march-main-images.top-left.mobile {
  display: none;
}

.march-main-images.top-right {
  width: 482px;
  top: -3em;
  right: -7em;
  z-index: 1;
}

.march-main-images.top-right.tablet {
  width: 380px;
  top: -2.3em;
}

.march-main-images.top-right.halftablet,
.march-main-images.top-right.mobile {
  width: 250px;
  top: -5.5em;
  left: -2em;
}

.march-main-images.bee {
  width: 38px;
  bottom: 35%;
  left: -5em;
}

.march-main-images.bee.tablet,
.march-main-images.bee.halftablet,
.march-main-images.bee.mobile {
  display: none;
}

.march-main-images.pink-light-left, .march-main-images.pink-light-right {
  width: 1000px;
  height: 1000px;
  background: radial-gradient(circle 500px, #d54d59cc, transparent 100%);
}

.march-main-images.pink-light-left {
  top: -75%;
  left: -50%;
}

.march-main-images.pink-light-left.tablet,
.march-main-images.pink-light-left.halftablet {
  top: -600px;
  left: -500px;
}

.march-main-images.pink-light-left.mobile {
  width: 500px;
  height: 500px;
  background: radial-gradient(circle 250px, #d54d5999, transparent 100%);
  top: -300px;
  left: -250px;
}

.march-main-images.pink-light-right {
  top: -20%;
  right: -120%;
  z-index: 1;
}

.march-main-images.pink-light-right.tablet,
.march-main-images.pink-light-right.halftablet,
.march-main-images.pink-light-right.mobile {
  display: none;
}

.march-main-button.tablet {
  font-weight: 600;
  font-size: 1em;
}

.march-main-button.halftablet {
  font-weight: 600;
  font-size: 0.8em;
}

.march-main-button.mobile {
  display: none;
}


.march-main-button.phone {
  display: none;
}

.march-main-button.phone.mobile {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 15;
  margin: 0 auto 1em auto;
  box-sizing: border-box;
  padding: 0.2em 1em;
  font-size: 0.9em;
  font-weight: 600;
  top: -1em;
}


.advantages-container {
  padding-top: 2em;
  margin-bottom: 5em;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  line-height: 1.2em;
}

.advantages-container.tablet {
  font-size: 0.8em;
}

.advantages-container.halftablet,
.advantages-container.mobile {
  font-size: 0.7em;
}

.advantages-container.mobile {
  padding-top: 0;
  margin-bottom: 2em;
}

.advantages-border {
  width: 100%;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(208, 145, 131, 0) 0%, rgba(255, 255, 255, 1) 7%, rgba(255, 255, 255, 1) 91%, rgba(208, 145, 131, 0) 100%);
  top: 0;
}

.advantages-border.mobile {
  display: none;
}

.advantages {
  text-align: center;
  width: 25%;
}

.advantages.halftablet {
  width: 30%;
}

.advantages.mobile {
  width: 32%;
}

.adv-number {
  font-size: 4.5em;
  line-height: 1em;
  font-weight: 400;
  font-style: italic;
}

.adv-number.tablet {
  font-size: 3.5em;
}

.adv-number.halftablet,
.adv-number.mobile {
  font-size: 2.5em;
  margin-bottom: 0.2em;
}


.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  position: relative;
}
  .product.book {
    flex-direction: row-reverse;
  }

.product.tablet {
  font-size: 0.8em;
  margin-bottom: -1em;
}

.product.halftablet,
.product.mobile {
  flex-direction: column-reverse;
  font-size: 0.8em;
  margin-bottom: -1em;
}

.product a {
  font-weight: 800;
}

.product.second {
  margin-bottom: 3em;
  align-items: center;
}

.product.second.tablet {
  margin-bottom: -1em;
}

.product.second.halftablet {
  margin-bottom: -1em;
  margin-top: 5em;
}

.product.second.mobile {
  margin-bottom: -1em;
  margin-top: 3em;
}

.product-text {
  width: 45%;
  margin-top: 2em;
  position: relative;
  z-index: 5;
}

.product-text.halftablet {
  width: 60%;
}

.product-text.mobile {
  width: 90%;
}

.product h3 {
  font-size: 3em;
  position: relative;
  margin-top: -2em;
  z-index: 2;
}

.product.tablet h3,
.product.halftablet h3,
.product.mobile h3 {
  font-size: 2.3em;
  line-height: 1em;
}

.product h3 span {
  font-family: 'TheArtist';
  position: absolute;
  top: -0.5em;
  left: -1em;
}

.product-buttons, .product-buttons-mobile {
  display: flex;
  align-items: center;
}

.product-buttons-mobile {
  display: none;
}

.product-buttons-mobile.tablet {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  margin-bottom: 2em;
}

.product-buttons-mobile.halftablet,
.product-buttons-mobile.mobile {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  margin-bottom: 2em;
  margin-top: 1em;
  flex-wrap: wrap;
}

.product-buttons.tablet,
.product-buttons.halftablet,
.product-buttons.mobile {
  display: none;
}

.product-button {
  font-size: 1em;
  font-weight: 600;
  padding: 1em 2em;
  margin-right: 2em;
}

.product-button.tablet,
.product-button.halftablet {
  margin-right: 2em;
  padding: 0.7em 2em;
}

.product-button.mobile {
  width: 100%;
  margin: 0 0 1em 0;
}

.product-price {
  display: flex;
  font-size: 3em;
  line-height: 2em;
  z-index: 2;
}
  p.old-price {
    text-decoration: line-through;
    color: #ffffff55;
    margin-left: 1em;
  }

.product-price.tablet,
.product-price.halftablet,
.product-price.mobile {
  border-top: 1px solid #ffffff55;
  margin-top: 0.7em;
  font-size: 2.5em;
}

.product-price .old {
  color: #ffffff55;
  text-decoration: line-through;
  margin-left: 1em;
}

.product-price.tablet .old,
.product-price.halfablet .old,
.product-price.mobile .old {
  margin-left: 0.5em;
}

.product-price.child {
  font-size: 2.5em;
}

.product-price-border {
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, rgba(208, 145, 131, 0) 0%, rgba(255, 255, 255, .3) 7%, rgba(255, 255, 255, 1) 91%, rgba(208, 145, 131, 0) 100%);
}

.product-price-border.tablet,
.product-price-border.halftablet,
.product-price-border.mobile {
  display: none;
}

.product-img-container {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(-5deg);
  z-index: 2;
  order: 1;
}

.product-img-container.halftablet {
  width: 70%;
  margin-bottom: -3em;
}

.product-img-container.mobile {
  width: 90%;
  margin-bottom: -3em;
}

.product-img {
  width: 100%;
  position: relative;
  z-index: 2;
}

.product-img.tablet,
.product-img.halftablet,
.product-img.mobile {
  width: 110%;
}

.product-img.child {
  z-index: 1;
}

.product-ribbon {
  position: absolute;
  width: 200px;
  height: 100px;
  background: url(~@/assets/march/pr-ribbon.png) no-repeat;
  background-size: 60%;
  color: #2f0630;
  font-size: 0.9em;
  font-weight: 800;
  z-index: 2;
  right: -5em;
}

.product-ribbon.tablet,
.product-ribbon.halftablet,
.product-ribbon.mobile {
  top: -1em;
}

.product-ribbon p {
  position: absolute;
  top: 22px;
  left: 55px;
}

.product-ribbon-icon {
  width: 19px;
  height: 13px;
  position: absolute;
  top: 21px;
  left: 30px;
}


.product-light {
  width: 800px;
  height: 800px;
  position: absolute;
  background: radial-gradient(circle 400px, #02667f, transparent 100%);
  z-index: 0;
  left: -20%;
  top: -15%;
}

.product-light.tablet,
.product-light.halftablet,
.product-light.mobile {
  width: 600px;
  background: radial-gradient(circle 300px, #02667f, transparent 100%);
}

.product-light.child {
  width: 600px;
  height: 600px;
  background: radial-gradient(circle 300px, #02667f, transparent 100%);
  top: -30%;
}

.product-vk {
  background: #d54d5977;
  border-radius: 1em;
  padding: 0em 10em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  margin-top: -2em;
  margin-bottom: 3em;
  position: relative;
  z-index: 1;
}

.product-vk.tablet {
  padding: 0em 3em;
  margin-top: 0em;
  margin-bottom: 1em;
}

.product-vk.halftablet,
.product-vk.mobile {
  padding: 0.5em 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.product-vk.mobile {
  flex-direction: column;
  padding: 11em 1em 1em 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.product-vk h3 {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3em;
  line-height: 1.3em;
}

.product-vk.tablet h3,
.product-vk.halftablet h3,
.product-vk.mobile h3 {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}

.product-vk.tablet p,
.product-vk.halftablet p,
.product-vk.mobile p {
  line-height: 1.3em;
}

.product-vk-img-block {
  position: relative;
  min-width: 195px;
}

.product-vk-img-block.mobile {
  position: absolute;
  top: -1em;
}

.product-vk-img {
  width: 195px;
  position: relative;
}

.buy-all {
  background-color: #e0395577;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.7em;
  position: relative;
  margin-bottom: 5em;
}

.buy-all.halftablet, .buy-all.mobile {
  flex-direction: column-reverse;
}

.product-button-all {
  font-weight: 600;
  padding: 1.2em 2em;
  z-index: 1;
}

.product-button-all.mobile {
  padding: 1.2em 1.5em;
}

.product-button-all.end {
  font-size: 1em;
}

.buy-all-button {
  position: relative;
}

.buy-all-button.halftablet {
  margin-bottom: 3em;
}

.buy-all-button.mobile {
  margin-bottom: 1em;
}

.buy-all-button-bg {
  width: 340px;
  height: 173px;
  position: absolute;
  top: -70%;
  left: -10%;
  z-index: 0;
}

.buy-all-button-bg.mobile {
  width: 250px;
  top: -40%;
}

.buy-all-text {
  margin-right: 10%;
}

  .buy-all-text p.small {
    font-size: 1.3em;
    line-height: 1.5em;
    font-style: italic;
  }
  .buy-all-text.tablet p.small,
  .buy-all-text.halftablet p.small,
  .buy-all-text.mobile p.small {
    font-size: 1em;
    font-weight: 400;
  }

.buy-all-text.mobile {
  margin-right: 0;
}

.buy-all p {
  font-size: 3em;
  line-height: 1em;
  text-align: center;
}

.buy-all.tablet p,
.buy-all.halftablet p,
.buy-all.mobile p {
  font-size: 2em;
  font-weight: 600;
}

.buy-all p.old {
  font-size: 1.5em;
  color: #ffffff55;
  text-decoration: line-through;
  font-weight: 400;
}

.buy-all.tablet p.old,
.buy-all.halftablet p.old,
.buy-all.mobile p.old {
  font-size: 1.1em;
}

.buy-all-bee {
  width: 35px;
  position: absolute;
  left: 8%;
  bottom: 10%;
}

.buy-all-bee.tablet {
  left: -20px;
}

.buy-all-bee.halftablet {
  left: 1em;
  bottom: 30%;
}

.buy-all-bee.mobile {
  left: -0.7em;
  bottom: 30%;
}

.buy-all-petals {
  position: absolute;
  width: 360px;
  z-index: 0;
  left: 10%;
}

.buy-all-petals.tablet {
  width: 250px;
  left: 0;
}

.buy-all-petals.halftablet,
.buy-all-petals.mobile {
  display: none;
}

.reviews {
  position: relative;
  margin-bottom: 2em;
}
  .reviews.author {
    margin-bottom: 6em;
  }

.reviews p {
  width: 70%;
  margin: 2em auto 0 auto;
  line-height: 1.3em;
}

.reviews.tablet,
.reviews.halftablet,
.reviews.mobile {
  margin-bottom: 4em;;
}

.reviews.mobile {
  margin-bottom: 3em !important;
}

.review-dark {
  width: 150px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.reviews.tablet .review-dark,
.reviews.halftablet .review-dark {
  width: 100px;
}

.reviews.mobile .review-dark {
  width: 50px;
}

.review-dark.left {
  background: linear-gradient(270deg, rgba(2, 58, 77, 0) 0%, rgba(2, 58, 77, 1) 100%);
  left: 0;
}

.review-dark.right {
  background: linear-gradient(90deg, rgba(2, 58, 77, 0) 0%, rgba(2, 58, 77, 1) 100%);
  right: 0;
}

.reviews.tablet,
.reviews.halftablet,
.reviews.mobile {
  width: 90%;
  margin: 0 auto 6em auto;
}

.reviews.halftablet,
.reviews.mobile {
  width: 90%;
  margin-top: -20pt;
}

.doll-mobility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.4em;
  position: relative;
  margin-bottom: 3em;
}

.doll-mobility.tablet,
.doll-mobility.halftablet,
.doll-mobility.mobile {
  flex-direction: column-reverse;

  margin-bottom: 4em;
}

.doll-mobility-text {
  width: 40%;
}

.doll-mobility-text.halftablet,
.doll-mobility-text.mobile {
  width: 80%;
}

.doll-mobility-img-block {
  width: 50%;
  position: relative;
}

.doll-mobility-img-block.tablet {
  width: 50%;
  min-width: 0;
  top: -2em;
  margin-bottom: -4em;
}

.doll-mobility-img-block.halftablet,
.doll-mobility-img-block.mobile {
  width: 90%;
  min-width: 0;
  top: -2em;
  margin-bottom: -4em;
}

.doll-mobility-img {
  width: 100%;
  position: relative;
}

.doll-mobility-img.tablet,
.doll-mobility-img.halftablet,
.doll-mobility-img.mobile {
  width: 110%;
  right: 1em;
  min-width: 0;
}

.doll-mobility ul li {
  margin-bottom: 1em;
}

.doll-mobility.tablet ul,
.doll-mobility.halftablet ul,
.doll-mobility.mobile ul {
  margin-bottom: 2em;
}
</style>

<style scoped>
.faq {
  font-size: 0.9em;
}

h2.faq {
  font-size: 3em;
  margin-bottom: 0.5em;
}
h2.faq.mobile {
  font-size: 2em;
}

h2.center {
  text-align: center;
  margin-bottom: 1em;
}

a.text-chapter {

}

:deep(.accordion__panel button) {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

:deep(.accordion__panel .accordion__panel-header-toggle) {
  width: 100%;
  display: block;
  text-align: left;
  border: 0px solid transparent;
  border-radius: 0.5em;
  background: #00000022;
  color: white;
  padding: 0.8em 1.5em;
  margin-bottom: 0.5em;
}

:deep(.accordion__panel-content) {
  font-size: 0.9em;
  margin: 0 2em 1.5em 2em;
  animation-name: open;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
</style>